import payload_plugin_e81h812gyu from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_HEOniK1d7v from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_XRT3VSGsLc from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vite@6.1.0_@ty_b05835377d3a02cd71a9845c144b7601/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_1utZxiyBFV from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IVOqpYUZ0k from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6LBIx98hu9 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FvQzPJp8Pk from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uZq7Bu3NJx from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bwKVoxck7A from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_76k3KHBGNm from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_JriiifrYjw from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_bufferutil@4.0.9_db0@0.2.3_ioredi_ff4f5105a08e89388a3c62b97668d2d0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WVeQevz6f1 from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_3c1d860d3615c153f13c462375fc42e6/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_us0DH082sl from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vite@6.1.0_@ty_b05835377d3a02cd71a9845c144b7601/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/app/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_e81h812gyu,
  revive_payload_client_HEOniK1d7v,
  vuetify_icons_XRT3VSGsLc,
  unhead_1utZxiyBFV,
  router_IVOqpYUZ0k,
  payload_client_6LBIx98hu9,
  navigation_repaint_client_FvQzPJp8Pk,
  check_outdated_build_client_uZq7Bu3NJx,
  chunk_reload_client_bwKVoxck7A,
  plugin_vue3_76k3KHBGNm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JriiifrYjw,
  plugin_WVeQevz6f1,
  vuetify_no_client_hints_us0DH082sl,
  plugin_wy0B721ODc,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]